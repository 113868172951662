import construction from "./254519-P4LH8O-12.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={construction} className="App-logo" alt="logo" />
        <p>Coming Soon</p>
        Until then, you can reach out through my other
        <a href="https://linktr.ee/martindimovwd" className="App-link">
          profiles
        </a>
      </header>
    </div>
  );
}

export default App;
